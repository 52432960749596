import React, { Component } from 'react';
import "./Home.css"
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'


const Button = styled.button`
    padding: 13px 22px;
    border-style: solid;
    border-width: 2px;
    border-color: #0c1537;
    border-radius: 22.5px;
    background-color: white;
    font-size: 14px;
    color : #111c3d;
    font-weight : 700;
    text-align : center;
    display : inline-block;
    `;


class Home extends Component {
    variant = { variant: "btn btn-success" }
    alignRight = { right: "right" }
    alignLeft = { left: "left" }

    

    render() {

        return (
            <div className="row ">
                <div className="medium-12 columns">



                    <header className="navbar-fixed-top">
                        <nav>
                            <ul>
                                <li><a href="#about">Overview</a></li>
                                <li><a href="#experience">Roadmap</a></li>
                                <li><a href="#projects">Specs</a></li>
                                <li><a href="#contact">Join Us</a></li>
                                <li><a href="#faq">FAQ</a></li>

                            </ul>

                            <ul className="nav navbar-nav navbar-right">

                                <p>
                                    <Button style={{ position: 'relative', left: '-50px', top: '-5px' }} {...this.variant} onClick={() => window.location.href = "/Minting"}>MINT RHINO</Button>
                                </p>
                            </ul>

                            <ul className="nav navbar-nav navbar-left">

                                <a href="/" style={{ position: 'relative', left: '50px', top: '0px' }}><img alt="" src="img/Home/logo.png" height="45" width="80" /></a>
                            </ul>
                        </nav>
                    </header>

                    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                        <img src="img/Home/logo.png" onClick={()=>window.location.href="/"} width="60" height="50" alt="" className="rhino-mobile" />
                        <button className="menu-btn" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <img src="img/Home/menu.svg" width="40" height="30" alt="" className="mx-3" />
                        </button>

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#about">Overview <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#experience">Roadmap</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#projects">Specs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#contact">Join Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#faq">FAQ</a>
                                </li>
                            </ul>
                            <br />
                            <ul className="nav-item">
                                <p>
                                    <Button {...this.variant} onClick={() => window.location.href = "/Minting"}>MINT RHINO</Button>
                                </p>
                            </ul>

                        </div>
                    </nav>



                    <div className="section" id="home" data-stellar-background-ratio="0.5">
                        <div className="container">
                            <div className="disply-table">
                                <div className="table-cell" data-aos="fade-up" data-aos-delay="0">
                                    
                                    <h1>Project<br /> Rhino</h1> </div>
                            </div>
                        </div>
                    </div>



                    <div className="section" id="about">
                        <div className="container">
                            <div className="col-md-6" data-aos="fade-up">
                                <h4>01</h4>
                                <h1 className="size-50"> Overview</h1>
                                <div className="h-50" style={{position:"absolute", top:0}}></div>
                                <p>Project Rhino is a collection of 7,777 unique painted style abstract Rhino NFTs on the Solana Blockchain which gives back to charity and has a lottery and giveaways for holders. Rhinos are awesome but sadly on average one rhino is poached every 22 hours, so with this drop we will do what we can to support the endangered rhinos.</p>
                                <p>This the start of something big with plans of developing a marketplace and a magazine which focuses on photography and art in the topics of the natural world, culture, science and legends. This is all while supporting causes, our photographers and artists and of course giving back to our holders.</p>

                                <div className="h-50"></div>
                            </div>
                            <div className="col-md-6 about-img-div">

                                <img src="img/Home/background collage.jpg" width="600" className="img-responsive" alt="" {...this.alignRight} data-aos="fade-right" data-aos-delay="0" />
                            </div>
                        </div>
                    </div>



                    <div className="section" id="experience">
                        <div className="container">
                            <div className="col-md-12">
                                <h4>02</h4>
                                <h1 className="size-50">Roadmap </h1>
                                <div className="h-50"></div>
                            </div>
                            <div className="col-md-12">
                                <ul className="timeline">
                                    <li className="timeline-event" data-aos="fade-up">
                                        <label className="timeline-event-icon"></label>
                                        <div className="timeline-event-copy">
                                            <p className="timeline-event-thumbnail">1</p>
                                            <h3>Project Shark NFT Sale</h3>

                                            <p><strong>Pre-Sale</strong>
                                                <br />Pre-Sale for early community members and social media competition winners.</p>
                                            <p><strong>Public Sale</strong>
                                                <br />Launch of the rest of the rhinos to the amazing community.</p>
                                        </div>
                                    </li>
                                    <li className="timeline-event" data-aos="fade-up" data-aos-delay=".2">
                                        <label className="timeline-event-icon"></label>
                                        <div className="timeline-event-copy">
                                            <p className="timeline-event-thumbnail">2</p>
                                            <h3>Charity</h3>
                                            <p>Proactively supporting and protecting the rhinos is important to us at Project Rhino. Therefore, one of our first actions will be to donate 20% of sales to a rhino charity which will be decided on by the community.</p>
                                        </div>
                                    </li>
                                    <li className="timeline-event" data-aos="fade-up" data-aos-delay=".4">
                                        <label className="timeline-event-icon"></label>
                                        <div className="timeline-event-copy">
                                            <p className="timeline-event-thumbnail">3</p>
                                            <h3>Lottery</h3>
                                            <p>Project Rhino holders will be automatically included in a lottery following our launch, with a total of $15,000 up for grabs and rare rhinos.</p>

                                        </div>
                                    </li>
                                    <li className="timeline-event" data-aos="fade-up" data-aos-delay=".2">
                                        <label className="timeline-event-icon"></label>
                                        <div className="timeline-event-copy">
                                            <p className="timeline-event-thumbnail">4</p>
                                            <h3>Gallery</h3>
                                            <p>In our gallery, you will be able to see every rhino and the rank in order of rarity.</p>
                                        </div>
                                    </li>
                                    <li className="timeline-event" data-aos="fade-up" data-aos-delay=".2">
                                        <label className="timeline-event-icon"></label>
                                        <div className="timeline-event-copy">
                                            <p className="timeline-event-thumbnail">5</p>
                                            <h3>Roadmap 2.0</h3>
                                            <p><strong>Marketplace</strong>
                                                <br />With help from our community we will create a marketplace which will be the home to the most exciting and upcoming photographers and artists. What makes this marketplace unique is that it will focus on the natural world, culture, science and legends. We will actively search for the brightest talents.</p>
                                            <p><strong>Magazine</strong>
                                                <br />A monthly magazine with focus on the topics of natural world, culture, science and legends will be created. This magazine special is that the images will be NFTs from our marketplace, which will bring talent to the limelight. The rhino holders will guide the creation of the magazine.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div className="section" id="projects">
                        <div className="container">
                            <div className="col-md-12">
                                <h4>03</h4>
                                <h1 className="size-50"> Specs</h1>
                            </div>

                            <div className="main-container portfolio-inner clearfix">

                                <div className="portfolio-div">
                                    <div className="portfolio">

                                        <div className="categories-grid wow fadeInLeft">
                                            <nav className="categories">
                                                <ul className="portfolio_filter">


                                                    <li><a href="/" className="active" data-filter=".logo">Body</a></li>
                                                    <li><a href="/" data-filter=".photography">Background</a></li>
                                                    <li><a href="/" data-filter=".graphics">First Horn</a></li>
                                                    <li><a href="/" data-filter=".ads">Second Horn</a></li>
                                                    <li><a href="/" data-filter=".fashion">Head Accessory</a></li>
                                                    <li><a href="/" data-filter=".wing">Wings</a></li>
                                                    <li><a href="/" data-filter=".birds">Birds</a></li>
                                                </ul>
                                            </nav>
                                        </div>



                                        <div id="theLink" className="no-padding portfolio_container clearfix" data-aos="fade-up">

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a  className="portfolio_item" href="/#" id="theLink"> <img src="img/portfolio/Body/Normal.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Normal</span> <em>14.87%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a className="portfolio_item" href="/#" id="theLink"> <img src="img/portfolio/Body/Black.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Black</span> <em>14.11%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Green.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Green</span> <em>10.55%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a  href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Red.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Red</span> <em>9.57%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Purple.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Purple</span> <em>9.64%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Yellow.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Yellow</span> <em>9.97%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Multicoloured.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Multicoloured</span> <em>6.91%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Camouflage.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Camo</span> <em>7.22%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/African Art.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>African Art</span> <em>5.28%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Diamond.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Diamond</span> <em>3.12%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Silver.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Silver</span> <em>3.55%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Gold.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold</span> <em>3.15%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>




                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Fire.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Fire</span> <em>0.89%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div className="col-md-4 col-sm-6  logo">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Body/Solana.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana</span> <em>1.17%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>








                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/White.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>White</span> <em>12.04%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Black.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Black</span> <em>11.24%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Blue.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Blue</span> <em>10.61%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Green.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Green</span> <em>11.21%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Red.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Red</span> <em>10.49%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Yellow.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Yellow</span> <em>11.41%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Sand.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Sand</span> <em>7.50%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Sunset.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Sunset</span> <em>4.21%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Waterhole.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Waterhole</span> <em>7.08%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Rainbow.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Rainbow</span> <em>5.04%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Clouds.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Clouds</span> <em>2.98%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Fire.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Fire</span> <em>3.29%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            

                                            
                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Solana.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana</span> <em>1.94%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 photography">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Background/Space.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Space</span> <em>0.94%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        

                                        


                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Ivory.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ivory</span> <em>23.98%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Copper.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Copper</span> <em>19.50%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Silver.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Silver</span> <em>13.81%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Gold.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold</span> <em>10.72%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Ruby.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ruby</span> <em>6.02%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Sapphire.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Sapphire</span> <em>6.19%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Emerald.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Emerald</span> <em>6.33%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Diamond.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Diamond</span> <em>4.23%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Ammolite.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ammolite</span> <em>3.24%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Pink Diamond.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Pink Diamond</span> <em>3.11%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Solana.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana</span> <em>2.02%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                    
                                            <div className="col-md-4 col-sm-6 graphics">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/First Horn/Crystal.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Crystal</span> <em>0.85%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        





                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Ivory.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ivory</span> <em>24.83%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Copper.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Copper</span> <em>18.96%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Silver.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Silver</span> <em>13.94%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Gold.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold</span> <em>10.14%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Ruby.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ruby</span> <em>6.16%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Sapphire.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Sapphire</span> <em>6.42%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Emerald.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Emerald</span> <em>5.87%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Diamond.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Diamond</span> <em>4.19%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            
                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Ammolite.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ammolite</span> <em>3.16%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Pink Diamond.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Pink Diamond</span> <em>3.07%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Solana.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana</span> <em>2.30%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div className="col-md-4 col-sm-6 ads">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Second Horn/Crystal.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Crystal</span> <em>0.96%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                      

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Silver Halo.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Silver Halo</span> <em>6.65%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Rose Gold Halo.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Rose Gold Halo</span> <em>5.49%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Gold Halo.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold Halo</span> <em>5.32%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Solana Halo.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana Halo</span> <em>4.51%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Silver Crown.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Silver Crown</span> <em>3.91%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Rose Gold Crown.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Rose Gold Crown</span> <em>4.17%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>




                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Gold Crown.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold Crown</span> <em>3.18%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            

                                            <div className="col-md-4 col-sm-6  fashion">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Head Accessory/Solana Crown.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana Crown</span> <em>1.94%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>



                                            <div className="col-md-4 col-sm-6  wing">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Wing/White Wing.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>White Wing</span> <em>10.57%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            



                                            <div className="col-md-4 col-sm-6  wing">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Wing/Black Wing.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Black Wing</span> <em>6.32%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  wing">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Wing/Gold Wing.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Gold Wing</span> <em>3.19%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  wing">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Wing/Solana Wing.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Solana Wing</span> <em>1.12%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        


                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Oxpecker.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Oxpecker</span> <em>10%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Blue Kingfisher.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Blue Kingfisher</span> <em>10.71%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Dove.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Dove</span> <em>10.11%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Lilac-Breasted-Roller.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Lilac-Breasted-Roller</span> <em>5.18%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Hoopoes.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Hoopoes</span> <em>5.16%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Cape Parrot.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Cape Parrot</span> <em>5.09%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Flamingo.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Flamingo</span> <em>1.96%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/African Fish Eagle.jpg" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>African Fish Eagle</span> <em>2.18%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        

                                            

                                            <div className="col-md-4 col-sm-6  birds">
                                                <a href="/#" className="portfolio_item"> <img src="img/portfolio/Birds/Ostrich.png" alt="" className="img-responsive" />
                                                    <div className="portfolio_item_hover">
                                                        <div className="portfolio-border clearfix">
                                                            <div className="item_info"> <span>Ostrich</span> <em>1.21%</em> </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                           


                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>




                    <div className="section" id="contact">
                        <div className="container">
                            <div className="col-md-6" data-aos="fade-up">
                                <h4>04</h4>
                                <h1 className="size-50"> Join Us</h1>
                                <div className="h-50"></div>
                                <h3 >Social Network</h3>
                                <ul className="social" >
                                    <li><a href="https://twitter.com/ProjectrhinoNFT" target="_blank" rel="noreferrer noopener"><i className="ion-social-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/projectrhinonft/" target="_blank" rel="noreferrer noopener"><i className="ion-social-instagram"></i></a></li>
                                    <li><a href="https://discord.gg/Wr9F9ScJGM" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faDiscord}></FontAwesomeIcon></a></li>
                                </ul>


                                <div className="h-50"></div>
                            </div>
                            <div className="col-md-6 joinus-img-div">

                                <img src="img/Home/Joinus.png" width="1500" className="img-responsive" alt="" {...this.alignLeft} data-aos="fade-right" data-aos-delay="0" />
                            </div>
                        </div>
                    </div>














                    <div id="faq" className="section">

                        <div id="btn-close-modal" className="close-animatedModal close-popup-modal"> <i className="ion-close-round"></i> </div>
                        <div className="clearfix"></div>
                        <div className="modal-content">
                            <div className="container">
                                <div className="portfolio-padding">
                                    <div className="col-md-12">
                                        <h2 >FAQ</h2>
                                        <div className="h-50"></div>
                                        <h3>What is the total supply of Project Rhino?</h3>
                                        <p>7,777 abstract Rhino NFTs.</p>


                                        <h3>How can I mint a Rhino?</h3>
                                        <p>Using a Solana Wallet, we recommend Phantom.</p>


                                        <h3>When will the launch be?</h3>
                                        <p>Mid December. The exact date will be announced on Twitter and Discord. Make sure you follow us so you don't miss out.</p>


                                        <h3>What will the mint price be?</h3>
                                        <p>0.5 Sol for pre-sale and 1 Sol for public sale. But of course the price can change depending on the value of Solana.</p>


                                        <h3>Why on the Solana Blockchain?</h3>
                                        <p>The Solana Blockchain provides cheap gas fees, instant transactions and the most exciting ecosystem.</p>


                                        <h3>What are the benefits of owning a Rhino?</h3>
                                        <p>Not only will you have the chance of winning the lottery and supporting a charity, the Rhino owners will have special benefits when it comes to the future marketplace, magazine and giveaways.</p>

                                        <h3>More Questions?</h3>
                                        <p>Ask us on Twitter and Discord, just click the links on the Join Us part of the page.</p>
                                        <br /> </div>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        );
    }
}
export default Home;