import React, { Component } from 'react';
import "./Home.css"
import "./Minting.css"



class Minting extends Component {
    variant = { variant: "btn btn-success" }
    alignRight = { right: "right" }
    alignLeft = { left: "left" }

    render() {

        return (

            
            <div className="section" id="comingsoon" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="disply-table">
                        <div className="COMINGSOON" data-aos="fade-up" data-aos-delay="0">
                            <h1 className="COMINGSOON">COMING SOON</h1> 
                        </div>
                    </div>
                </div>
            </div>



                    
                 
                
            
        );
    }
}
export default Minting;